import { useContext, useEffect, useState } from "react"
import { Button, Tabs, Typography, Select } from "antd"
import { LoadingOutlined, PlayCircleOutlined, ReadOutlined, RedoOutlined, UserAddOutlined } from "@ant-design/icons"
import { NotificationInstance } from "antd/es/notification/interface"
import { useMachine } from "@xstate/react"
import { useParams } from "react-router-dom"
import styled from "styled-components"

import { ApiSimulatorTab, ApiDocsTab, ApiUsersTab } from "../components/Simulator"
import { simulatorMachine } from "../machines/SimulatorMachine"
import { Centered, Colors, PageWrapper } from "../components/global"
import { OptionItemWrapper } from "../components/Designer/FieldForm"
import { AuthItem } from "../machines/SimulatorModel"
import { GlobalStateContext } from ".."

const { Title } = Typography

export interface LocalStorageSessions {
    // projectId: string
    [key: string]: {
        // env name
        [key: string]: AuthItem[]
    }
}

export default function SimulatorPage(props: { notificationsApi: NotificationInstance }) {
    const { projectId } = useParams()
    const [current, send] = useMachine(simulatorMachine)
    const [envDropdownOpen, setEnvDropdownOpen] = useState<boolean>(false)
    const { sessionId } = useContext(GlobalStateContext)

    // This useEffect is needed to sync localStorage with state of the machine while adding new sessions
    useEffect(() => {
        const sessions = JSON.parse(localStorage.getItem("simulator-sessions") || "{}") as LocalStorageSessions

        if (current.context.apiAuth.authSessionsList.length > 0) {
            if (sessions[current.context.projectId]) {
                sessions[current.context.projectId][current.context.gqlSimulator.env] = current.context.apiAuth.authSessionsList
            } else {
                sessions[current.context.projectId] = {
                    [current.context.gqlSimulator.env]: current.context.apiAuth.authSessionsList,
                }
            }
        } else {
            if (sessions[current.context.projectId]) {
                sessions[current.context.projectId][current.context.gqlSimulator.env] = []
            }
        }
        localStorage.setItem("simulator-sessions", JSON.stringify(sessions))
    }, [current.context.apiAuth.authSessionsList])

    if (current.context.debug) {
        useEffect(() => {
            const cleanContext: any = { ...current.context }
            delete cleanContext.projectConfig
            delete cleanContext.generatedEndpoints
            console.log(
                `Simulator machine state value changed:\n${JSON.stringify(current.value, null, 4)};\n\n\nWith context:\n${JSON.stringify(
                    cleanContext,
                    null,
                    4
                )}.`
            )
        }, [current.value])
    }

    if (!current.context.notificationsApi) {
        send({ type: "UPDATE_NOTIFICATIONS_API", notificationsApi: props.notificationsApi })
    }

    if (current.matches("none")) {
        if (projectId) {
            send({ type: "FETCH", projectId, sessionId })
            return <></>
        } else {
            return (
                <PageWrapper>
                    <Centered vertical>
                        <Title level={2}>Missing project ID in URL...</Title>
                    </Centered>
                </PageWrapper>
            )
        }
    }

    if (current.matches("globalLoading"))
        return (
            <PageWrapper>
                <Centered vertical>
                    <LoadingOutlined style={{ fontSize: "75px", color: Colors.primary }} />
                    <Title level={2}>Loading project endpoints...</Title>
                </Centered>
            </PageWrapper>
        )

    if (current.matches("error"))
        return (
            <PageWrapper>
                <Centered vertical>
                    <Title level={2}>Error loading project endpoints: try again...</Title>
                    <Button
                        type="primary"
                        icon={<RedoOutlined />}
                        onClick={() => {
                            send("TRY_AGAIN")
                        }}
                    >
                        Load project endpoints
                    </Button>
                </Centered>
            </PageWrapper>
        )

    const envOptions = [
        { label: "Development", value: "dev" },
        { label: "Production", value: "production" },
    ]

    return (
        <>
            <FloatingSelect
                value={envOptions.find((env) => env.value === current.context.gqlSimulator?.env)?.label || "Development"}
                placeholder="Select environment..."
                title="Select environment..."
                open={envDropdownOpen}
                onDropdownVisibleChange={setEnvDropdownOpen}
                style={{ minWidth: "300px" }}
                onSelect={(label) => {
                    const selectedEnv = label === "Production" ? "production" : "dev"
                    send({
                        type: "SELECT_SIMULATOR_ENV",
                        env: selectedEnv,
                    })
                }}
            >
                {envOptions.map((env) => (
                    <Select.Option value={env.label} key={env.value}>
                        <OptionItemWrapper>{env.label}</OptionItemWrapper>
                    </Select.Option>
                ))}
            </FloatingSelect>

            {/* FIXME: after implement version control */}
            {/* <Select
                //value={current.context.gqlSimulator.version}
                disabled
                placeholder="Select version..."
            /> */}

            <Tabs
                activeKey={
                    [
                        "default.tabs.simulatorWrapper.gqlSimulator.observeEndpointsList",
                        "default.tabs.simulatorWrapper.gqlSimulator.queryFilter",
                        "default.tabs.simulatorWrapper.gqlSimulator.querySelectorFields",
                        "default.tabs.simulatorWrapper.gqlSimulator.mutationForm",
                        "default.tabs.simulatorWrapper.gqlSimulator.codeSample",
                    ].some(current.matches)
                        ? "SIMULATOR"
                        : current.matches("default.tabs.docs")
                          ? "DOCS"
                          : [
                                  "default.tabs.apiUsers.selfSignup.credential",
                                  "default.tabs.apiUsers.loginSession",
                                  "default.tabs.apiUsers.loginSession.sessions",
                                  "default.tabs.apiUsers.loginSession.login.loginForm",
                                  "default.tabs.apiUsers.loginSession.login.logingIn",
                                  "default.tabs.apiUsers.selfSignup.credential",
                                  "default.tabs.apiUsers.selfSignup.profile.profileForm",
                                  "default.tabs.apiUsers.selfSignup.profile.creatingUser",
                                  "default.tabs.apiUsers.selfSignup.confirmation.confirmForm",
                                  "default.tabs.apiUsers.selfSignup.confirmation.confirmingEmail",
                                  "default.tabs.apiUsers.emailInvitation.checkingState",
                                  "default.tabs.apiUsers.emailInvitation.login.loginForm",
                                  "default.tabs.apiUsers.emailInvitation.login.logingIn",
                                  "default.tabs.apiUsers.emailInvitation.sessions",
                                  "default.tabs.apiUsers.emailInvitation.signup.emailAddress",
                                  "default.tabs.apiUsers.emailInvitation.signup.profile.profileForm",
                                  "default.tabs.apiUsers.emailInvitation.signup.profile.creatingUser",
                                  "default.tabs.apiUsers.emailInvitation.signup.confirmation.confirmForm",
                                  "default.tabs.apiUsers.emailInvitation.signup.confirmation.confirmingInvitation",
                              ].some(current.matches)
                            ? "API_USERS"
                            : ""
                }
                centered
                onChange={(key) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    send(key)
                }}
                tabBarStyle={{ zIndex: 2, background: "white" }}
                items={[
                    {
                        key: "SIMULATOR",
                        label: (
                            <span>
                                <PlayCircleOutlined style={{ marginRight: "5px", marginTop: "5px" }} />
                                Live API
                            </span>
                        ),
                        children: <ApiSimulatorTab current={current} send={send} notificationsApi={props.notificationsApi} />,
                        disabled: [
                            "default.tabs.apiUsers.loginSession.login.logingIn",
                            "default.tabs.apiUsers.selfSignup.profile.profileForm",
                            "default.tabs.apiUsers.selfSignup.confirmation.confirmForm",
                            "default.tabs.apiUsers.selfSignup.profile.creatingUser",
                            "default.tabs.apiUsers.selfSignup.confirmation.confirmingEmail",
                            "default.tabs.apiUsers.emailInvitation.signup.profile.profileForm",
                            "default.tabs.apiUsers.emailInvitation.signup.profile.creatingUser",
                            "default.tabs.apiUsers.emailInvitation.signup.confirmation.confirmForm",
                            "default.tabs.apiUsers.emailInvitation.signup.confirmation.confirmingInvitation",
                        ].some(current.matches),
                    },
                    {
                        key: "DOCS",
                        label: (
                            <span>
                                <ReadOutlined style={{ marginRight: "5px", marginTop: "5px" }} />
                                Tech Docs
                            </span>
                        ),
                        children: <ApiDocsTab current={current} send={send} notificationsApi={props.notificationsApi} />,
                        disabled: [
                            "default.tabs.apiUsers.loginSession.login.logingIn",
                            "default.tabs.apiUsers.selfSignup.profile.profileForm",
                            "default.tabs.apiUsers.selfSignup.confirmation.confirmForm",
                            "default.tabs.apiUsers.selfSignup.profile.creatingUser",
                            "default.tabs.apiUsers.selfSignup.confirmation.confirmingEmail",
                            "default.tabs.apiUsers.emailInvitation.signup.profile.profileForm",
                            "default.tabs.apiUsers.emailInvitation.signup.profile.creatingUser",
                            "default.tabs.apiUsers.emailInvitation.signup.confirmation.confirmForm",
                            "default.tabs.apiUsers.emailInvitation.signup.confirmation.confirmingInvitation",
                        ].some(current.matches),
                    },
                    {
                        key: "API_USERS",
                        label: (
                            <span>
                                <UserAddOutlined style={{ marginRight: "5px", marginTop: "5px" }} />
                                API Users
                            </span>
                        ),
                        children: <ApiUsersTab current={current} send={send} notificationsApi={props.notificationsApi} />,
                    },
                ]}
            />
        </>
    )
}

const FloatingSelect = styled(Select)`
    position: absolute;
    top: 7px;
    right: 20px;
    z-index: 3;
`
