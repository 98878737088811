import { createModel } from "xstate/lib/model"
import type { Config, User, EntityField, AccessItem, ConfigEntity } from "../coreTypes/config"
import { Project } from "./ProjectsMachine"
import { NotificationInstance } from "antd/es/notification/interface"

type UserFormType = {
    originalUser?: User
    user: User
}

type FieldFormType = {
    originalField?: EntityField
    field: EntityField
    formType?: string
    parentFieldUsedinEntityFieldNames?: string[]
}

type AccessFormType = {
    originalAccessItem?: AccessItem
    accessItem: AccessItem
    conditionsTypes: string[]
    accessItemIndex: number
}

type EntityFormType = {
    originalEntity?: ConfigEntity
    entity: ConfigEntity
}

type SubObjectFormType = {
    originalSubObject?: {
        name: string
        fields: EntityField[]
    }
    subObject: {
        name: string
        fields: EntityField[]
    }
}

type EnumFormType = {
    name: string
    originalName?: string
    values: string[]
}

type ConfigSaveResult = {
    projectDiff: {
        latestUpdatedAtISOString?: string
        latestVersion?: string
    }
    projectConfigDiff: {
        latestUpdatedAtISOString?: string
        latestVersion?: number
    }
}

type ConfigDeployResult = {
    projectDiff: {
        deployedAtISOString?: string
        deployedVersion?: string
    }
    projectConfigDiff: {
        apiVersion?: number
        updatedAtISOString?: string
    }
    configReport: ConfigReport
}

type ConfigReport = {
    version: string
    projectId: string
    configId: string
    tests: Tests
    createdAt: string
    startedTestsTime: string
    status: string
}
/**
 * Interface for the generated tests below
 */
interface TestApiCallReduced {
    entityName: string
    accessItem: AccessItem
    cleanGqlQuery?: {
        query: string
        variables?: { [key: string]: any }
    }
    testRespParsedBody?: any
    resultId?: string
    executionDurationMs?: number
    warnings?: string[]
}

interface Tests {
    [key: string]: {
        [key: string]: TestApiCallReduced[]
    }
}

export type { UserFormType, FieldFormType, AccessFormType, EntityFormType, SubObjectFormType, EnumFormType }

export const DesignerModel = createModel(
    // Initial context
    {
        notificationsApi: undefined as undefined | NotificationInstance,

        projectId: "",
        project: {} as Project,
        projectConfig: {} as Config,
        configReport: {} as ConfigReport,

        // Top-level user form
        userForm: {
            user: {
                groupName: "",
                invitable: false,
                signupable: false,
                userData: {
                    trackChanges: false,
                    defaultFields: {
                        _id: "USER_ID",
                        createdAt: "TIME",
                        createdBy: "USER_ID",
                        updatedAt: "TIME",
                        status: "USER_STATUS",
                        email: "EMAIL",
                        invitedBy: "USER_ID",
                    },
                    fields: [],
                    access: [],
                },
            } as User,
        } as UserFormType,

        // Top-level entity form
        entityForm: {
            entity: {
                entityName: "",
                fields: [],
                defaultFields: {
                    _id: "OBJECT_ID",
                    createdAt: "TIME",
                    updatedAt: "TIME",
                    createdBy: "USER_ID",
                },
                trackChanges: false,
                access: [],
            } as ConfigEntity,
        } as EntityFormType,

        // Inner field form
        fieldForm: {
            field: {
                name: "",
                description: "",
                type: "",
                mutable: true,
                mandatory: false,
                isArray: false,
            } as EntityField,
        } as FieldFormType,

        // Inner access permissinons list and form
        accessItems: [] as AccessItem[],
        accessForm: {
            accessItem: {
                type: "USERGROUP",
                rule: {
                    type: "ANY",
                    name: "",
                    description: "",
                },
                fields: [],
            },
            conditionsTypes: [],
            accessItemIndex: -1,
        } as AccessFormType,

        // Sub-object form
        subObjectForm: {
            subObject: {
                name: "",
                fields: [],
            },
        } as SubObjectFormType,

        // Enum form
        enumForm: { name: "", values: [] } as EnumFormType,

        // Other
        debug: false,
        sessionId: "",
    },
    {
        // Event creators
        events: {
            // Global events
            UPDATE_NOTIFICATIONS_API: (notificationsApi: NotificationInstance) => ({ notificationsApi }),
            FETCH: (projectId: string, sessionId: string) => ({ projectId, sessionId }),
            FETCHED: (project: any) => ({ project }),
            ERROR: (err: string) => ({ err }),
            TRY_AGAIN: () => ({}),

            SAVED: (msg: string, configId: string, configSaveResult: ConfigSaveResult) => ({
                msg,
                configId,
                configSaveResult,
            }),

            DEPLOY_VERSION: () => ({}),
            DEPLOYED: (configDeployResult: ConfigDeployResult) => ({ configDeployResult }),

            USERS: () => ({}),
            ENTITIES: () => ({}),
            SUBOBJECTS: () => ({}),
            ENUMS: () => ({}),

            // User related events
            // Takes input "user" from component and transforms into event object to process below
            USER_FORM: (user: User | undefined) => ({ user }),
            CHANGE_USER_GROUP_NAME: (groupName: string) => ({ groupName }),
            CHANGE_USER_GROUP_DESCRIPTION: (description: string) => ({ description }),
            SWITCH_USER_GROUP_TRACK_CHANGES: () => ({}),
            SWITCH_INVITABLE: () => ({}),
            SWITCH_SIGNUPABLE: () => ({}),
            SAVE_USER: () => ({}),
            RESET_USER: () => ({}),
            DELETE_USER: () => ({}),
            CANCEL_USER: () => ({}),
            SET_INVITABLE: (groupName: string) => ({ groupName }),
            SET_EMAIL_INVITATION_URL: (url: string) => ({ url }),
            SET_USER_INVITATION_URL: (url: string) => ({ url }),

            // Field relared events
            FIELD_FORM: (field: EntityField | undefined, connectionFieldNames: string[] | undefined) => ({ field, connectionFieldNames }),
            SAVE_FIELD: () => ({}),
            EDIT_PERMISSION: () => ({}),
            NEW_PERMISSION: () => ({}),
            SAVE_PERMISSION: () => ({}),
            CHANGE_FIELD_NAME: (fieldName: string) => ({ fieldName }),
            RESET_FIELD: () => ({}),
            DELETE_FIELD: () => ({}),
            CANCEL_FIELD: () => ({}),
            CHANGE_FIELD_DESCRIPTION: (description: string) => ({ description }),
            CHANGE_FIELD_TYPE: (typeValue: string) => ({ typeValue }),
            SWITCH_MANDATORY: () => ({}),
            SWITCH_ISARRAY: () => ({}),
            SWITCH_MUTABLE: () => ({}),
            CHANGE_CONNECTION_UNDERLYING_FIELD_NAME: (fieldName: string) => ({ fieldName }),
            SET_REMOTE_FIELD_NAME: (fieldName: string) => ({ fieldName }),
            SWITCH_UNDERLYING_MANDATORY: () => ({}),
            SWITCH_UNDERLYING_ISARRAY: () => ({}),
            SWITCH_UNDERLYING_MULTABLE: () => ({}),

            // Access permissions related events
            OBSERVE_PERMISSIONS: (accessItems: AccessItem[]) => ({ accessItems }),
            HIDE_PERMISSIONS: () => ({}),
            PERMISSION_FORM: (accessItem: AccessItem, accessItemIndex: number) => ({ accessItem, accessItemIndex }),
            ADD_CONDITION: () => ({}),
            SET_USER_GROUP_NAME: (groupName: string) => ({ groupName }),
            SWITCH_RESTRICTION: () => ({}),
            SELECT_ANDCONDITION_OBJECT_FIELD: (fieldName: string, index: number) => ({ fieldName, index }),
            SWITCH_ACCESS_FIELDS: () => ({}),
            CHANGE_ACCESS_TYPE: () => ({}),
            DELETE_ACCESS_PERMISSION: () => ({}),
            RESET_ACCES_PERMISSION: () => ({}),
            CANCEL_ACCESS_PERMISSION: () => ({}),
            SAVE_ACCESS_PERMISSION: () => ({}),
            CHANGE_RULE_NAME: (name: string) => ({ name }),
            CHANGE_RULE_DESCRIPTION: (description: string) => ({ description }),
            SET_USER_ACCESS_VALUE: (userField: string, index: number) => ({ userField, index }),
            DELETE_CONDITION: (index: number) => ({ index }),
            CHANGE_FIELD_ACCESS: (fieldPath: string, accessType: string) => ({ fieldPath, accessType }),

            // Entities related events
            ENTITY_FORM: (entity: ConfigEntity | undefined) => ({ entity }),
            CHANGE_ENTITY_NAME: (entityName: string) => ({ entityName }),
            CHANGE_ENTITY_DESCRIPTION: (description: string) => ({ description }),
            SWITCH_TRACK_ENTITY_CHANGES: () => ({}),
            SAVE_ENTITY: () => ({}),
            RESET_ENTITY: () => ({}),
            CANCEL_ENTITY: () => ({}),
            DELETE_ENTITY: () => ({}),

            // Sub-objects related events
            SUBOBJECT_FORM: (subObject: { name: string; fields: EntityField[] } | undefined) => ({ subObject }),
            COLLAPSE_FORM: () => ({}),
            DELETE_SUBOBJECT: (key: string) => ({ key }),
            SAVE_SUBOBJECT: () => ({}),
            CHANGE_SUBOBJECT_NAME: (name: string) => ({ name }),

            // Enum related events
            ENUM_FORM: (enumForm: { name: string; values: string[] }) => ({ enumForm }),
            SAVE_ENUM: () => ({}),
            DELETE_ENUM: (enumKey: string) => ({ enumKey }),
            CANCEL_ENUM: () => ({}),
            CHANGE_ENUM_NAME: (name: string) => ({ name }),
            CHANGE_ENUM_VALUES: (values: string[]) => ({ values }),
        },
    }
)
