import axios from "axios"
import { createModel } from "xstate/lib/model"
import { NotificationInstance } from "antd/es/notification/interface"

export type Project = {
    _id?: string
    name: string
    // (immutable -> stored in AWS Cognito) This is the name that will be displayed to the external/public within transactional emails (invitations, verifications, etc.)
    publicName: string
    description: string
    //
    latestStatus: string
    latestUpdatedAtISOString?: string
    latestVersion?: string
    deployedAtISOString?: string
    deployedVersion?: string
    isSample?: boolean
}

type ProjectFormType = {
    originalProject?: Project
    project: Project
}

export const ProjectsModel = createModel(
    // Initial context
    {
        projectForm: {
            project: {
                name: "",
                description: "",
                publicName: "",
                latestStatus: "",
            } as Project,
        } as ProjectFormType,
        projects: [] as Project[],
        notificationsApi: undefined as undefined | NotificationInstance,
    },
    {
        // Event creators
        events: {
            // Global events
            UPDATE_NOTIFICATIONS_API: (notificationsApi: NotificationInstance) => ({ notificationsApi }),
            FETCHED: (projects: Project[]) => ({ projects }),
            SELECT_PROJECT: (project?: Project) => ({ project }),
            DELETE_PROJECT: (projectId: string) => ({ projectId }),
            CANCEL_PROJECT: () => ({}),
            SAVE_PROJECT: () => ({}),
            CHANGE_PROJECT_NAME: (projectName: string) => ({ projectName }),
            CHANGE_PROJECT_PUBLIC_NAME: (publicName: string) => ({ publicName }),
            CHANGE_DESCRIPTION: (description: string) => ({ description }),
            ERROR: (err: string) => ({ err }),
            SUCCESS: (msg: string) => ({ msg }),
            TRY_AGAIN: () => ({}),
        },
    }
)

export const projectsMachine = ProjectsModel.createMachine(
    {
        id: "projects",

        // Local context for entire machine
        context: ProjectsModel.initialContext,

        // Initial state
        initial: "globalLoading",

        on: {
            UPDATE_NOTIFICATIONS_API: {
                actions: ProjectsModel.assign({ notificationsApi: (ctx, e) => e.notificationsApi }),
            },
        },

        // States
        states: {
            globalLoading: {
                type: "atomic",
                invoke: {
                    id: "projectsMachine-fetch",
                    src: "fetch",
                },
                on: {
                    FETCHED: {
                        target: "projectsOverview",
                        actions: ProjectsModel.assign({
                            projects: (_, e) => e.projects,
                        }),
                    },
                    ERROR: {
                        target: "error",
                    },
                },
            },
            error: {
                type: "atomic",
                on: {
                    TRY_AGAIN: {
                        target: "globalLoading",
                    },
                },
            },
            projectsOverview: {
                on: {
                    SELECT_PROJECT: {
                        actions: [
                            ProjectsModel.assign((ctx, e) => {
                                if (e.project)
                                    return {
                                        projectForm: { project: e.project, originalProject: e.project },
                                    }

                                return {
                                    projectForm: {
                                        project: ProjectsModel.initialContext.projectForm.project,
                                    },
                                }
                            }),
                        ],
                        target: "projectForm",
                    },
                },
            },
            projectForm: {
                on: {
                    CANCEL_PROJECT: { target: "projectsOverview" },
                    DELETE_PROJECT: {},
                    SAVE_PROJECT: {
                        target: ".savingProject",
                    },
                    CHANGE_PROJECT_NAME: {
                        actions: [
                            ProjectsModel.assign((ctx, e) => ({
                                projectForm: { ...ctx.projectForm, project: { ...ctx.projectForm.project, name: e.projectName } },
                            })),
                        ],
                    },
                    CHANGE_PROJECT_PUBLIC_NAME: {
                        actions: [
                            ProjectsModel.assign((ctx, e) => ({
                                projectForm: { ...ctx.projectForm, project: { ...ctx.projectForm.project, publicName: e.publicName } },
                            })),
                        ],
                    },
                    CHANGE_DESCRIPTION: {
                        actions: [
                            ProjectsModel.assign((ctx, e) => ({
                                projectForm: { ...ctx.projectForm, project: { ...ctx.projectForm.project, description: e.description } },
                            })),
                        ],
                    },
                    SUCCESS: {
                        target: "globalLoading",
                    },
                },
                states: {
                    default: {},
                    savingProject: {
                        type: "atomic",
                        invoke: {
                            id: "projectsMachine-save",
                            src: "save",
                        },
                        on: {
                            ERROR: {
                                target: "default",
                            },
                        },
                    },
                },
            },
        },
    },
    {
        services: {
            fetch: (ctx) => async (send) => {
                const session = localStorage.getItem("session")
                const sessionObj = session && JSON.parse(session)

                axios
                    .get(`${process.env.REACT_APP_QUORINI_API}/list-projects`, {
                        headers: { Authorization: sessionObj?.accessToken },
                    })
                    .then((response) => {
                        // console.log(response)
                        if (response.status == 200) {
                            send({ type: "FETCHED", projects: response.data })
                        } else {
                            send("ERROR")
                        }
                    })
                    .catch((e) => {
                        console.error(e)
                        send("ERROR")
                    })
            },
            save: (ctx) => async (send) => {
                const session = localStorage.getItem("session")
                const sessionObj = session && JSON.parse(session)

                axios
                    .post(`${process.env.REACT_APP_QUORINI_API}/save-project`, ctx.projectForm.project, {
                        headers: { Authorization: sessionObj?.accessToken },
                    })
                    .then((response) => {
                        // console.log(response)
                        if (response.status == 200) {
                            send({ type: "SUCCESS", msg: "Successfully saved project" })
                            ctx.notificationsApi?.success({
                                message: "Saved project",
                                description: "Your project was successfully saved!",
                                placement: "bottomLeft",
                                duration: 5,
                            })
                        } else {
                            throw new Error("save-project API call failed")
                        }
                    })
                    .catch((e) => {
                        console.error(e)
                        send("ERROR")
                        ctx.notificationsApi?.error({
                            message: "Error",
                            description: "Failed saving project, try again...",
                            placement: "bottomLeft",
                            duration: 5,
                        })
                    })
            },
        },
        actions: {
            trackAction: (context, event) => {
                console.log("ACTION_EVENT", event.type)
            },
        },
    }
)
