import { useContext, useEffect } from "react"
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import { useActor } from "@xstate/react"
import { ConfigProvider, notification, Typography } from "antd"
import styled from "styled-components"
import { LoadingOutlined } from "@ant-design/icons"

import { GlobalStateContext } from "."
import "./App.css"
import LogoNav from "./components/LogoNav"
import { Centered, Colors, PageWrapper } from "./components/global"

import DesignerPage from "./pages/Designer"
import SimulatorPage from "./pages/Simulator"
import Projects from "./pages/Projects"
import AuthPage from "./pages/AuthPage"
import ActivationPage from "./pages/ActivationPage"
import QuestionaryPage from "./pages/QuestionaryPage"
import logo from "./assets/logo.svg"

const { Title } = Typography

function App() {
    const { authService } = useContext(GlobalStateContext)
    const [authState] = useActor(authService)
    const isAuthenticating = authState.matches("checkAuth.default")
    const isAuthenticated = authState.matches("checkAuth.authenticated")

    const [notificationsApi, notificationsContextHolder] = notification.useNotification({})

    if (authState.context.debug) {
        useEffect(() => {
            const cleanContext: any = { ...authState.context }
            delete cleanContext.projectConfig
            console.log(
                `Auth machine authState value changed:\n${JSON.stringify(authState.value, null, 4)};\n\n\nWith context:\n${JSON.stringify(
                    cleanContext,
                    null,
                    4
                )}.`
            )
        }, [authState.value])
    }

    const GlobaLoader = () => (
        <PageWrapper>
            <Centered vertical>
                <img src={logo} alt="quorini-logo" />
                <LoadingOutlined style={{ fontSize: "75px", color: Colors.primary }} />
                <Title level={2}>Loading account details...</Title>
            </Centered>
        </PageWrapper>
    )

    return (
        <AppWrapper>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: Colors.primary,
                    },
                    // components: {
                    //     Input: {
                    //         boxShadow: "0 0 0 3px rgba(0, 0, 0, 0.2)",
                    //     },
                    // },
                }}
            >
                {notificationsContextHolder}
                <BrowserRouter>
                    <Routes>
                        <Route
                            path="/projects"
                            element={
                                isAuthenticated ? (
                                    authState.context.isActive ? (
                                        !authState.context.isQuestionary ? (
                                            <QuestionaryPage />
                                        ) : (
                                            <Projects notificationsApi={notificationsApi} />
                                        )
                                    ) : (
                                        <Navigate to={"/activation"} />
                                    )
                                ) : isAuthenticating ? (
                                    <GlobaLoader />
                                ) : (
                                    <Navigate to={"/"} />
                                )
                            }
                        />

                        <Route path=":projectId">
                            <Route path="" element={<Navigate to={"/"} />} />
                            <Route
                                path="designer"
                                element={
                                    isAuthenticated ? (
                                        authState.context.isActive ? (
                                            <DesignerPage notificationsApi={notificationsApi} />
                                        ) : (
                                            <Navigate to={"/activation"} />
                                        )
                                    ) : isAuthenticating ? (
                                        <GlobaLoader />
                                    ) : (
                                        <Navigate to={"/"} />
                                    )
                                }
                            />
                            <Route
                                path="simulator"
                                element={
                                    isAuthenticated ? (
                                        authState.context.isActive ? (
                                            <SimulatorPage notificationsApi={notificationsApi} />
                                        ) : (
                                            <Navigate to={"/activation"} />
                                        )
                                    ) : isAuthenticating ? (
                                        <GlobaLoader />
                                    ) : (
                                        <Navigate to={"/"} />
                                    )
                                }
                            />
                            {/* <Route path="explorer" element={} /> */}
                            {/* <Route path="metrics" element={} /> */}
                        </Route>

                        <Route
                            path="/auth"
                            element={
                                isAuthenticated ? (
                                    authState.context.isActive ? (
                                        <Navigate to={"/projects"} />
                                    ) : (
                                        <Navigate to={"/activation"} />
                                    )
                                ) : (
                                    <AuthPage />
                                )
                            }
                        />

                        <Route
                            path="/activation"
                            element={
                                isAuthenticated ? (
                                    authState.context.isActive ? (
                                        <Navigate to={"/projects"} />
                                    ) : !authState.context.isQuestionary ? (
                                        <QuestionaryPage />
                                    ) : (
                                        <ActivationPage />
                                    )
                                ) : (
                                    <Navigate to={"/auth"} />
                                )
                            }
                        />

                        <Route
                            path="/"
                            element={
                                isAuthenticated ? (
                                    authState.context.isActive ? (
                                        <Navigate to={"/projects"} />
                                    ) : (
                                        <Navigate to={"/activation"} />
                                    )
                                ) : (
                                    <Navigate to={"/auth"} />
                                )
                            }
                        />

                        <Route path="*" element={<Navigate to={"/"} />} />
                    </Routes>

                    {isAuthenticated && (
                        <>
                            <LogoNav />
                        </>
                    )}
                </BrowserRouter>
            </ConfigProvider>
        </AppWrapper>
    )
}

const AppWrapper = styled.div``

export default App
