import { FC, useState } from "react"
import styled from "styled-components"
import { Colors, ItemWithFadeInAnimation, OneThirdContainer, Spaces, StyleHelpers, TwoThirdContainer } from "../../global"
import { CopyBlock, github } from "react-code-blocks"
import { SimulatorMachineSend, SimulatorMachineState } from "../../../machines/SimulatorMachine"
import { NotificationInstance } from "antd/es/notification/interface"
import { APIHeaderSection } from "../ApiSimulatorTab"
import { Typography } from "antd"

const { Title } = Typography

interface Props {
    current: SimulatorMachineState
    send: SimulatorMachineSend
    notificationsApi: NotificationInstance
}

export const ApiDocsTab: FC<Props> = ({ current, send, notificationsApi }) => {
    if (!current.context.notificationsApi) {
        send({ type: "UPDATE_NOTIFICATIONS_API", notificationsApi })
    }

    // FIXME: need to implement states for sidebar items and switch between them on click
    // need to render current.context.queriesAndMutations in 2nd item
    // 3rd item has to be external link to our notion which opens new tab in browser
    // all need icon for 3rd item showing that it's extaernal link like typical square with arrow in right top corner

    return (
        <div style={{ height: "calc(100vh - 50px)", display: "flex", flexDirection: "column" }}>
            <APIHeaderSection>
                <Title level={2} style={{ marginTop: 0, marginBottom: 0 }}>
                    Live API documentation
                </Title>
            </APIHeaderSection>
            <ApiDocsTabWrapper>
                <TwoThirdContainer style={{ backgroundColor: "white", order: 2, padding: "10px 0 10px 20px" }}>
                    {current.matches("default.tabs.docs.typescriptTypes") && (
                        <CopyBlock
                            text={current.context.clientTypes ?? "Client-side TypeScript types here"}
                            language="graphql"
                            showLineNumbers={false}
                            wrapLongLines
                            theme={github}
                        />
                    )}
                    {current.matches("default.tabs.docs.graphqlQueries") && (
                        <CopyBlock
                            text={current.context.queriesAndMutations ?? "GraphQL API queries code here"}
                            language="graphql"
                            showLineNumbers={false}
                            wrapLongLines
                            theme={github}
                        />
                    )}
                </TwoThirdContainer>

                <OneThirdContainer style={{ order: 1, padding: "0" }}>
                    <SideMenuWrapper>
                        <SideMenuItem
                            index={1}
                            onClick={() => {
                                send({ type: "TYPESCRIPT_TYPES" })
                            }}
                            selected={current.matches("default.tabs.docs.typescriptTypes")}
                        >
                            Client-side TypeScript types
                        </SideMenuItem>
                        <SideMenuItem
                            index={2}
                            onClick={() => {
                                send({ type: "GRAPHQL_QUERIES" })
                            }}
                            selected={current.matches("default.tabs.docs.graphqlQueries")}
                        >
                            GraphQL API queries
                        </SideMenuItem>
                        <SideMenuItem
                            index={3}
                            onClick={() => {
                                window.open("https://docs.quorini.com/Quorini-API-documentation-1f3198e605ed4dabb2dde427587c20cf", "_blank")
                            }}
                        >
                            Integration guide and SDK
                        </SideMenuItem>
                    </SideMenuWrapper>
                </OneThirdContainer>
            </ApiDocsTabWrapper>
        </div>
    )
}

const ApiDocsTabWrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding-top: 0;
    width: 100%;
    height: calc(100vh - 130px);
    background-color: ${Colors.background};
    flex-grow: 1;
`

const SideMenuWrapper = styled.div`
    display: flex;
    padding: ${Spaces.medium} ${Spaces.normal};
    flex-direction: column;
`

const SideMenuItem = styled(ItemWithFadeInAnimation)<{ index: number; selected?: boolean; disabled?: boolean }>`
    width: 100%;
    border-radius: ${StyleHelpers.radiusSmall};
    padding: ${Spaces.normal};
    margin-bottom: ${Spaces.normal};
    background-color: white;
    border: ${(props) => (props.selected ? `2px solid ${Colors.primary}` : "unset")};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    font-weight: 600;
    color: ${(props) => (props.disabled ? Colors.grayNormal : Colors.primary)};

    animation-delay: ${(props: { index: number }) => props.index * 0.15}s; /* delay animation start for each item */

    box-shadow: ${StyleHelpers.staticBoxShadow};

    :hover {
        box-shadow: ${(props) => (props.selected ? "unset" : props.disabled ? StyleHelpers.staticBoxShadow : StyleHelpers.lightBoxShadow)};
        transform: ${(props) => (props.selected || props.disabled ? "unset" : "scale(1.01)")};
    }
`
