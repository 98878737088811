import React, { createContext } from "react"
import ReactDOM from "react-dom/client"
import { useInterpret } from "@xstate/react"
import { ActorRefFrom } from "xstate"

import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { authMachine } from "./machines/AuthMachine"
import axios from "axios"

interface GlobalStateContextType {
    authService: ActorRefFrom<typeof authMachine>
    sessionId: string
}

export const GlobalStateContext = createContext({} as GlobalStateContextType)

export const GlobalStateProvider = (props: { children: JSX.Element }) => {
    const authService = useInterpret(authMachine)

    const sessionId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)

    const session = localStorage.getItem("session")
    const sessionObj = session && JSON.parse(session)
    axios
        .post(
            `${process.env.REACT_APP_QUORINI_API}/track-action`,
            {
                eventName: "APP_SESSION_INIT",
                sessionId,
            },
            {
                headers: { Authorization: sessionObj?.accessToken },
            }
        )
        .catch((e) => {
            console.error(e)
        })

    return (
        <GlobalStateContext.Provider
            value={{
                authService,
                // generate random id for session
                sessionId,
            }}
        >
            {props.children}
        </GlobalStateContext.Provider>
    )
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
    <React.StrictMode>
        <GlobalStateProvider>
            <App />
        </GlobalStateProvider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
