import { useContext, useState } from "react"
import axios from "axios"
import { Form, Typography, Select, Space, Button, Input } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import { useActor } from "@xstate/react"

import { GlobalStateContext } from ".."
import logo from "../assets/logo.svg"
import { Mask } from "./AuthPage"
import { Centered, Colors } from "../components/global"

const { Title, Paragraph } = Typography
const { Option } = Select

export default function QuestionaryPage() {
    const [form] = Form.useForm()
    const [isLoading, setIsLoading] = useState(false)

    const session = localStorage.getItem("session")
    const sessionObj = session && JSON.parse(session)

    const { authService } = useContext(GlobalStateContext)
    const [authState, authSend] = useActor(authService)

    const onFinish = (values: any) => {
        setIsLoading(true)
        axios
            .post(`${process.env.REACT_APP_QUORINI_AUTH_API}/save-questionary`, values, {
                headers: { Authorization: sessionObj?.accessToken },
            })
            .then((response) => {
                // console.log(response)
                if (response.status == 200) {
                    setIsLoading(false)
                    authSend({ type: "SET_QUESTIONARY_STATUS", isQuestionary: true })
                } else {
                    throw new Error("save-questionary API call failed")
                }
            })
            .catch((e) => {
                console.error(e)
                setIsLoading(false)
                // TODO:
                // ctx.notificationsApi?.error({
                //     message: "Error",
                //     description: "Failed saving questionary, try again...",
                //     placement: "bottomLeft",
                //     duration: 5,
                // })
            })
    }

    const onSkip = () => {
        setIsLoading(true)
        axios
            .post(
                `${process.env.REACT_APP_QUORINI_AUTH_API}/save-questionary`,
                { skipped: true },
                {
                    headers: { Authorization: sessionObj?.accessToken },
                }
            )
            .then((response) => {
                // console.log(response)
                if (response.status == 200) {
                    setIsLoading(false)
                    authSend({ type: "SET_QUESTIONARY_STATUS", isQuestionary: true })
                } else {
                    throw new Error("save-questionary API call failed")
                }
            })
            .catch((e) => {
                console.error(e)
                setIsLoading(false)
                // TODO:
                // ctx.notificationsApi?.error({
                //     message: "Error",
                //     description: "Failed saving questionary, try again...",
                //     placement: "bottomLeft",
                //     duration: 5,
                // })
            })
        setIsLoading(false)
    }

    if (isLoading)
        return (
            <Mask>
                <img src={logo} alt="quorini-logo" />
                <Centered vertical>
                    <LoadingOutlined style={{ fontSize: "65px", color: Colors.primary }} />
                    <Title level={3}>Saving questionary...</Title>
                </Centered>
            </Mask>
        )

    return (
        <Mask>
            <img src={logo} alt="quorini-logo" />
            <Title level={2} style={{ paddingTop: "0px", paddingBottom: "40px" }}>
                Thank you for signing up!
            </Title>
            <Paragraph style={{ paddingTop: "0px", paddingBottom: "0px" }}>Please provide additional information.</Paragraph>
            <Paragraph style={{ paddingTop: "0px", paddingBottom: "0px" }}>This will help us to assist you during onboarding.</Paragraph>

            <Form
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                labelWrap={true}
                colon={false}
                layout="horizontal"
                scrollToFirstError
                form={form}
                name="signup-questionary"
                onFinish={onFinish}
                style={{ width: "400px", paddingTop: "20px" }}
            >
                <Form.Item name="role" label="Role" rules={[{ required: true }]}>
                    <Select placeholder="What is your role?" allowClear>
                        <Option value="ceo">CEO / Founder</Option>
                        <Option value="cto">CTO / Tech lead / Head of engineering</Option>
                        <Option value="pm"> Product manager</Option>
                        <Option value="fs">Full-stack developer</Option>
                        <Option value="fe">Front-end developer</Option>
                        <Option value="be">Back-end developer</Option>
                        <Option value="qa">Quality assurance</Option>
                        <Option value="devops">DevOps engineer</Option>
                        <Option value="other">Other</Option>
                    </Select>
                </Form.Item>
                <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.role !== currentValues.role}>
                    {({ getFieldValue }) =>
                        getFieldValue("role") === "other" ? (
                            <Form.Item name="customizeRole" label="Tell us more" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                        ) : null
                    }
                </Form.Item>

                <Form.Item name="size" label="Company size" rules={[{ required: true }]}>
                    <Select placeholder="What is the size of your company? " allowClear>
                        <Option value="1">1-9 people</Option>
                        <Option value="10">10-25 people</Option>
                        <Option value="25">25-50 people</Option>
                        <Option value="50">50-100 people</Option>
                        <Option value="100">100+ people</Option>
                        <Option value="other">Other</Option>
                    </Select>
                </Form.Item>
                <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.size !== currentValues.size}>
                    {({ getFieldValue }) =>
                        getFieldValue("size") === "other" ? (
                            <Form.Item name="customizeSize" label="Tell us more" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                        ) : null
                    }
                </Form.Item>

                <Form.Item name="purpose" label="Purpose" rules={[{ required: true }]}>
                    <Select placeholder="What are you going to use Quorini for?" allowClear>
                        <Option value="build-myself">Build backend for new mobile/web app for my company</Option>
                        <Option value="migrate">Migrate existing tech stack</Option>
                        <Option value="optimize">Use it within my company to optimize costs</Option>
                        <Option value="prototype">Create fast MVP to test an idea</Option>
                        <Option value="build-client">Build backend for my client</Option>
                        <Option value="other">Other</Option>
                    </Select>
                </Form.Item>
                <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.purpose !== currentValues.purpose}>
                    {({ getFieldValue }) =>
                        getFieldValue("purpose") === "other" ? (
                            <Form.Item name="customizePurpose" label="Tell us more" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                        ) : null
                    }
                </Form.Item>

                <Form.Item name="industry" label="Industry" rules={[{ required: true }]}>
                    <Select placeholder="Inductry of your business" allowClear>
                        <Option value="software">Software Development</Option>
                        <Option value="marketing">Marketing </Option>
                        <Option value="retail">Retail </Option>
                        <Option value="healthcare">Healthcare</Option>
                        <Option value="finance">Banking / Finance</Option>
                        <Option value="consulting">Consulting</Option>
                        <Option value="utilities">Utilities / Energy</Option>
                        <Option value="insurance">Insurance</Option>
                        <Option value="telecom">Telecommunications</Option>
                        <Option value="entertainment">Entertainment</Option>
                        <Option value="other">Other</Option>
                    </Select>
                </Form.Item>
                <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.industry !== currentValues.industry}>
                    {({ getFieldValue }) =>
                        getFieldValue("industry") === "other" ? (
                            <Form.Item name="customizeIndustry" label="Tell us more" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                        ) : null
                    }
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Space style={{ float: "right" }}>
                        <Button htmlType="button" type="text" onClick={onSkip}>
                            Skip
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </Mask>
    )
}
